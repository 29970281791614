import { formatDistanceToNow } from "date-fns";
import { Avatar, Box, Flex, Link, Text } from "theme-ui"



const PostHeader = ({post}) => {
  return (
    <Flex sx={{ alignItems: 'center', alignContent: 'space-between', mr:'auto', py: '0.5rem'}}>
      <Box>
        <Link
          href={`/linkup/r/${post.subthread_id}`}
          sx={{
            fontWeight: 'bold',
            color: 'inherit', 
            textDecoration: 'none', 
            '&:hover': {
              color: 'primary'
          }}}
        >
          <Avatar 
            src={post.avatar_url ? post.avatar_url : `https://robohash.org/${post.subthread_id}/set=set3`}
            sx={{
              backgroundColor: 'white', 
              width: 40, 
              height: 40,
              borderRadius: 99999,
              border: '1px solid lightgrey',
            }} 
          />
        </Link>
      </Box>
      <Box sx={{fontSize: '14px'}}>
        <Link href={`/linkup/r/${post.subthread_id}`} 
          pl={2}
          sx={{
            fontWeight: 'bold',
            color: 'inherit', 
            textDecoration: 'none', 
            '&:hover': {
              color: 'primary'
          }}}
        >r/{post.subthread_id}</Link> • <Text sx={{fontStyle:'oblique', fontSize: '14px'}}>{`${formatDistanceToNow(new Date(post.created_at))} ago`}</Text>
      </Box>
    </Flex>
  )
}

export default PostHeader; 