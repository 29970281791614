import React, { useEffect, useRef, useState } from 'react';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';


const EmojiPickerComponent = ({quillRef}) => {

  const handleEmojiSelect = (emoji) => {
    // const textarea = document.querySelector('.comment-textarea');
    // console.log(textarea);
    // if (textarea) {
    //   const start = textarea.selectionStart;
    //   const end = textarea.selectionEnd;
    //   const text = textarea.value;
    //   const before = text.substring(0, start);
    //   const after = text.substring(end);
    //   const emojiString = emoji.native;
    //   setContent(`${before}${emojiString}${after}`);
    //   textarea.focus();
    // }
    const editor = quillRef.current.getEditor();
    editor.focus();
    if (editor) {
      const range = editor.getSelection();
      if (range) {
        editor.insertText(range.index, emoji.native);
        editor.setSelection(range.index + emoji.native.length); // Set the cursor after the emoji
        editor.focus();
      }
    }
  }



  return (
    <>
      
    <div className='emoji-picker-container'>
      <Picker 
        emojiSize={16}
        perLine={10}
        data={data} 
        onEmojiSelect={handleEmojiSelect} 
        
      />
    </div>
      
    </>
  )
};


export default EmojiPickerComponent;