import React from "react";
import { Box, Container, Heading } from "theme-ui";
import ParticlesBackground from "../particles-background";


const Introduction = () => {

  return (
    <Box sx={{ position: 'relative', overflow: 'hidden' }}>
      <ParticlesBackground />
      <Container id="home" py={[1,2,4]} sx={{
          position: 'relative',
          zIndex: 1,
          
          // color: 'text',
          // minHeight: '50vh'
        }}>
        <Heading pb={[5,5,4]} sx={{fontOpticalSizing:'auto', fontSize: [5,6,6]}}>William Tran</Heading>
        <Heading pl={[0,3,4,5,5,6]} sx={{variant:'text.caps', textAlign: ['left','left','right','right'], fontSize: [2, 3, 3, 3, 3, 4]}}>Dedicated, Focused and Driven on Delivering High-Quality Software Solutions</Heading>
        <div className="quote-container">
          <p className="quote">"Life is like engineering; it's all about making trade-offs."</p>
          <p className="quote-author">- Mark Horowitz</p>
        </div>
      </Container>
    </Box>
  );
};

export default Introduction;