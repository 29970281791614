import { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { Box, Select } from "theme-ui";
import SearchSubthreadBar from "../../../components/search/search-thread-bar";
import SearchSubthreadsResults from "../posts/search-subthreads.component";


const Subthreads = ({subname}) => {
  console.log(subname);
  // const {subname} = useParams();
  const [subthreadName, setSubthreadName] = useState("");
  const [subthreads, setSubthreads] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [error, setError] = useState(null);
  
  // const calculatedWidth = `${subname.length * 8}px`;
  const handleSearchResults = (results) => {
    setSearchResults(results);
  };

  useEffect(() => {
    if (subname) {
      setSubthreadName(subname);
    }
    
  }, [subname])
  
  const toggleSearchSubthread = (e) => {
    e.preventDefault();
    setIsSearching(isSearching => !isSearching);
  }

  const handleSearchSubname = () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    
    const requestOptions = {
      method: "GET",
      headers: headers,
    }
  
    fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/posts/find_sub`, requestOptions)
    .then((response) => {
  
      return response.json();
    })
    .then((data) => {
      console.log(data);
      if (data.subthreads && Array.isArray(data.subthreads)){
        setSubthreads(data.subthreads);
      } else {
        throw new Error('Unexpected data format');
      }
    })
    .catch((err) => {
      console.log(err);
    });
    
  }
  
  
  
  if (error) {
    return <div>Error: {error}</div>
  }
  if (!Array.isArray(subthreads)) {
    return <div>No subthreads available</div>
  }

  return (
    <>
    {isSearching === false ?
    <Box pb={2}>
      <Select 
        defaultValue={`t/${subthreadName}`} 
        onClick={toggleSearchSubthread}
        sx={{width: '25%'}}
      > 
        <option >{`t/${subthreadName}`}</option>
      </Select>
    </Box>
    :
     <SearchSubthreadsResults setSubthreadName={setSubthreadName} setIsSearching={setIsSearching} />
    }
    </>
  )
}

export default Subthreads;