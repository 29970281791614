import React, { useEffect, useState } from "react";
import { Container, Grid, Heading, Box, Button, Flex, Link, Text } from "theme-ui";
import ServiceCard from "./card/service-card";
import { interval } from "date-fns";
import { BsChevronDoubleLeft, BsChevronDoubleRight, BsChevronLeft, BsChevronRight } from "react-icons/bs";



const Services = () => {
  const [activeService, setActiveService] = useState(0);
  // const [expandedService, setExpandedService] = useState({0 : true});

  // const toggleDetails = (index) => {
  //   setExpandedService((prevState) => ({
  //     ...prevState,
  //     [index]: !prevState[index],
  //   }));
  // };

  const services = [
    {
      title: "Software Engineering",
      details: `
      Software engineering is a dynamic field in high demand, requiring continuous upskilling due to rapid technological advancements. 
      My rewarding experience spans from working with diverse languages including Go, Python, Java, JavaScript, and C# for the last 7 years. 
      Over the past 2 years, I have focused on Go, leveraging its balanced approach to tackle challenges and deliver impactful projects.
      
      
      `,
      image: "https://i.ibb.co/RBw4tQ1/pexels-negativespace-97077.jpg",
    },
    {
      title: "API Integrations",
      details: `
      APIs refer to a set of rules and protocols that allow different software applications to communicate with
      each other. Whether the protocol is REST or SOAP, our focus is to build robust, reliable, well-documented and 
      easy-to-consume APIs that is flexible to integrate and customize with other software systems. I have worked with integrating
      third party APIs bringing in loan transactions, account and deposit transactions for the bank as well as creating APIs for RPA tasks.
      `,
      image: "https://i.ibb.co/sH51bgZ/e9920824-04d9-4884-a206-47288d76d4f3.jpg",
    },
    {
      title: "RPA Solutions",
      details: `
      Growing companies often struggle with performing tedious tasks and manual processes that do not provide value. 
      This is where RPA shines. At my previous experience with the bank, I was able to provide 
      RPA solutions by working with cross functioning teams through applying system integrations, data transforms, task schedulers, 
      web scraping and bot creation. For some departments, I was able to increase their productivity by 150%.
      `,
      image: "https://i.ibb.co/WpRN1sq/102675c7-f6da-431d-87d9-cdade438d444.jpg",
    },
    {
      title: "gRPC Integrations",
      details: `
      gRPC stands for Google Remote Procedure Calls and is similar to APIs in terms of enabling communication between
      different software components or services and in most cases more performant. It uses Protocol Buffers which offers binary serialization, 
      and HTTP/2 transport to create high-perfomance, low-latency communication for microservices. The goal is to leverage this technology 
      where companies see fit.
      `,
      image: "https://i.ibb.co/nsLmgmd/0c730f45-468b-4a0a-88d2-cda745baa36b.jpg",
    },
  ];

  const handleNext = () => {
    setActiveService((prevIndex) => (prevIndex + 1) % services.length);
  };

  const handlePrev = () => {
    setActiveService((prevIndex) => (prevIndex - 1 + services.length) % services.length);
  };

  useEffect(() => {
    const interval = setInterval(handleNext, 20000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Container id="services" my={4} sx={{position: 'relative'}}>
      <Heading as={'h2'} sx={{ fontSize: [2, 4, '1.9em'] }}>Let's Collaborate. Our Shared Experience + Expertise.</Heading>
      <Text sx={{ fontSize: '1.1em', mt: 3 }}>
        Together, we can solve complex challenges by blending my years of hands-on experience in software engineering, 
        API integrations, RPA solutions, Database integrations and gRPC integrations with your domain expertise. Let’s combine our skills to create meaningful, 
        innovative solutions that drive real business value.
      </Text>
      <Flex 
        sx={{
          mt: 3,
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: ['column', 'row'],
          gap: 4,
        }}
      >

      <Link 
     
        sx={{
          variant: "button.toggleDarkmode",
          cursor: "pointer",
            textDecoration: "none",
            alignItems: "center",
            
        }}
        onClick={handlePrev}
      >
        <BsChevronDoubleLeft size={24} />
        
      </Link>
      <Grid pt={3} gap={4} columns={[1, 1, 1]}>
        <Box key={activeService}>
          <ServiceCard service={services[activeService]} />
        </Box>
      </Grid>
      <Link 
        sx={{
          variant: "button.toggleDarkmode",
          cursor: "pointer",
            textDecoration: "none",
            alignItems: "center",
        }}
        onClick={handleNext}
      >
        <BsChevronDoubleRight size={24}/>
      </Link>
      </Flex>
    </Container>
  );
};

export default Services;