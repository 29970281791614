import { useContext, useState } from "react";
import { Box, Button, Checkbox, Container, Flex, Heading, Label, Link } from "theme-ui"
import FormInput from "../../form/form-input";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useAlert } from "../../../contexts/alert";

import formatErrors from "../../utils/auth/auth.utils";
import { AuthContext } from "../../../contexts/auth";

const LinkupLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const { setJwtToken, toggleRefresh, setAuthUser } = useContext(AuthContext);
  const { showAlert, hideAlert } = useAlert();

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    let payload = {
      username: username,
      password: password,
    }

    const requestOptions = {
      method: "POST",
      headers: {
        'Content-Type':'application/json'
      },
      credentials: "include",
      body: JSON.stringify(payload),
    }

    fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/token/authenticate`, requestOptions)
    .then((resp) => {
      return resp.json();
    })
    .then((data) => {
      console.log("data:", data);
      if (data.error){
        let errorMessage = '';

        if (typeof data.error.message === 'object') {
          errorMessage = formatErrors(data.error.message);
        } else if (typeof data.error.message === 'string') {
          errorMessage = data.error.message;
        } else {
          errorMessage = 'Unknown error occurred';
        }

        showAlert(errorMessage, "alert");
      } else {
        setJwtToken(data.tokens.access_token);
        setAuthUser(data.user.username);
        hideAlert();
        toggleRefresh(true);
        navigate("/linkup");
      }
    })
    .catch(err => {
      showAlert(err.message, "alert");
    })
  }
  return (
    <Container mt={6} sx={{width: '20em'}}>
      <Heading mb={3}>Log into account</Heading>
      <Box as="form" onSubmit={handleSubmit} mb={3}>
        <FormInput 
          title="Username"
          type="username"
          name="username"
          autoComplete="username-new"
          onChange={(e) => setUsername(e.target.value)}
        />
        <FormInput  
          title="Password"
          type="password"
          name="password"
          autoComplete="password-new"
          onChange={(e) => setPassword(e.target.value)}
        />
        <Flex sx={{justifyContent:'space-between', alignItems: 'center'}}>
          <Button
            type="submit"
          >Login</Button>
          <Box  sx={{cursor:'pointer', color:'primary', ml: 'auto'}}>
            <Link href="/linkup/forgot-password" sx={{textDecoration: "none"}}>
              Forgot Password?
            </Link>
          </Box>
        </Flex>
      </Box>
    </Container>
  )
}

export default LinkupLogin;