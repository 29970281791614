import { useEffect, useState } from "react";
import { Box, Button, Divider, Flex, Heading, Link, Text } from "theme-ui";
import { useRecentPosts } from "../../../contexts/recentPosts";
import RecentPostsHeader from "../../../components/headers/RecentPostHeader";

const RecentPosts = () => {
  const {recentPosts, updateRecentPosts} = useRecentPosts();


  useEffect(() => {
    // Load recent posts from localStorage on component mount
    const storedPosts = JSON.parse(localStorage.getItem('recentPosts'));
    if (storedPosts && recentPosts.length === 0) {
      const validPosts = storedPosts.filter((post) => post.id && post.title && post.url);
      updateRecentPosts(validPosts);
    }
  }, []);

  const clearRecentPosts = () => {
    updateRecentPosts([]);
    localStorage.removeItem('recentPosts');
  }

  return (
    <>
      <Flex pt={2} my={2} sx={{ justifyContent: "space-between", alignItems: "center"}}>
        <Heading as="h3" sx={{  fontWeight: '450', lineHeight:'1.4', letterSpacing:'-0.06em', fontFamily: 'Roboto, sans-serif'}}>Recent Posts</Heading>
        <Button onClick={clearRecentPosts} variant="link">Clear</Button>
      </Flex>
      <Box>
        {recentPosts.length === 0 ? (
          <Text sx={{fontSize: '0.9rem'}}>No Recent posts.</Text>
        ) : (
          recentPosts.filter(post => post && post.id).map((post, index) => (
            <>
              <RecentPostsHeader post={post}/>
              <Link key={index} href={`/linkup/${post.url}`} sx={{ display: "block", my: 1, textDecoration: 'none', fontWeight: '500', color: 'inherit', '&:hover': {textDecoration: 'underline'} }}>
                {post.title}
              </Link>
              <Text sx={{fontSize: '0.9rem', color:'gray'}}>{post.upvotes} upvotes • {post.comment_count}</Text>
              <Divider sx={{color:'lightgray'}}/>
            </>
          ))
        )}
      </Box>
      {recentPosts.length > 10 && (
        <Button
          onClick={() => updateRecentPosts(recentPosts.slice(10))}
          variant="link"
          mt={2}
        >
          Show more
        </Button>
      )}
    </>
  )
}

export default RecentPosts;