import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';

import { ThemeUIProvider } from 'theme-ui';
import { theme } from './theme/theme.ts';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AlertProvider } from './contexts/alert.js';
import AuthProvider from './contexts/auth.js';
import { SubthreadsProvider } from './contexts/subthreads.js';
import { VoteProvider } from './contexts/vote.js';
import { RecentPostsProvider } from './contexts/recentPosts.js';
import { UserActivityProvider } from './contexts/userActivity.js';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeUIProvider theme={theme}>
      <AuthProvider>
        <BrowserRouter>
          <UserActivityProvider>
            <AlertProvider>
              <SubthreadsProvider> 
                <VoteProvider>
                  <RecentPostsProvider>
                    <App />
                  </RecentPostsProvider>
                </VoteProvider>
              </SubthreadsProvider>
            </AlertProvider>
          </UserActivityProvider>
        </BrowserRouter>
      </AuthProvider>
    </ThemeUIProvider>
  </React.StrictMode>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
