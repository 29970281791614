import { createContext, useContext, useState } from "react";

const SubthreadsContext = createContext();

export const SubthreadsProvider = ({children}) => {
  const [subthreads, setSubthreads] = useState([]);

  return (
    <SubthreadsContext.Provider value={{ subthreads, setSubthreads }}>
      {children}
    </SubthreadsContext.Provider>
  )
}

export const useSubthreads = () => useContext(SubthreadsContext);