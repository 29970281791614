import React from 'react';
import { Box, Flex } from 'theme-ui';
import { Outlet } from 'react-router-dom';
import Navigation from './navigation.component';


const Directory = ({ leftContent, rightContent }) => {

  return (
    <Flex sx={{ minHeight: '100vh', flexDirection: 'column' }}>
      {/* Navigation bar, fixed at the top */}
      <Box sx={{ width: '100%', zIndex: 100 }}>
        <Navigation />
      </Box>

      {/* Main content with left and right sidebars */}
      <Flex sx={{ flex: 1 }}>
        <Box sx={{
          display: ['none', 'none', 'block'],
          width: ['0', '0', '10%'],
          p: 3,
          bg: 'muted',
        }}>
          {leftContent}
        </Box>

        <Box sx={{
          width: ['100%', '100%', '80%'],
          p: 3,
          overflow: 'auto',  // Optional: controls scrolling for main content area
        }}>
          <Outlet />
        </Box>

        <Box sx={{
          display: ['none', 'none', 'block'],
          width: ['0', '0', '10%'],
          p: 3,
          bg: 'muted',
        }}>
          {rightContent}
        </Box>
      </Flex>
    </Flex>
  )
}

export default Directory;
