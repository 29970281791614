import { createContext, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import formatErrors from "../components/utils/auth/auth.utils";


export const AuthContext = createContext();

const AuthProvider = ({children}) => {
  const [jwtToken, setJwtToken] = useState("");
  const [authUser, setAuthUser] = useState("");
  const [userRole, setUserRole] = useState("");
  const [alertMsg, setAlertMsg] = useState("");

  const [tickInterval, setTickInterval] = useState(null);

  

  const toggleRefresh = useCallback((status) => {
    console.log("toggleRefresh clicked");

    if (status && !tickInterval) {
      console.log("starting token refresh interval...");
      let i = setInterval(() => {
        const requestOptions = {
          method: "GET",
          credentials: "include",
        };
        fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/token/refresh`, requestOptions)
        .then((response) => {
          console.log(response);
          return response.json();
        })
        .then((data) => {
          console.log(data);
          if (data.error) {
            let errorMessage = '';
            if (typeof data.error.message === 'object') {
              errorMessage = formatErrors(data.error.message);
            } else if (typeof data.error.message === 'string') {
              errorMessage = data.error.message;
            } else {
              errorMessage = 'Unknown error occurred';
            }
            setAlertMsg(errorMessage);
          } else {
            setJwtToken(data.tokens.access_token);
            setUserRole(data.user.role);
            setAuthUser(data.user.username);
            
          }

        })
        .catch((error) => {
          console.log("Error refreshing token:", error);
      })
      }, 600000);
      setTickInterval(i);
      console.log("setting tick interval to", i);
    } else if (!status) {
      console.log("turn off tickInterval", tickInterval);
      clearInterval(tickInterval);
      setTickInterval(null);
    }
  }, [tickInterval])

  useEffect(() => {
    if (!jwtToken) {
      const requestOptions = {
        method: "GET",
        credentials: "include",
      };

      fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/token/refresh`, requestOptions)
        .then((response) => {
          console.log(response);
          return response.json();
        })
        .then((data) => {
          console.log(data);
          if (data.error) {
            let errorMessage = '';
            if (typeof data.error.message === 'object') {
              errorMessage = formatErrors(data.error.message);
            } else if (typeof data.error.message === 'string') {
              errorMessage = data.error.message;
            } else {
              errorMessage = 'Unknown error occurred';
            }
            setAlertMsg(errorMessage);
          } else {
            setJwtToken(data.tokens.access_token);
            setUserRole(data.user.role);
            setAuthUser(data.user.username);
            if (!tickInterval) toggleRefresh(true);
          }

        })
        .catch((error) => {
          console.error("Error refreshing token:", error);
          setAlertMsg("Failed to refresh token");
      });
    }
    return () => {
      if (tickInterval) {
        clearInterval(tickInterval);
      }
    };
  }, [jwtToken, toggleRefresh, tickInterval]);


  const value = {
    jwtToken,
    setJwtToken,
    authUser,
    setAuthUser,
    alertMsg,
    setAlertMsg,
    toggleRefresh,
    userRole,
    setUserRole
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;