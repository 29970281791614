import { useEffect } from "react";
import { useAlert } from "../../contexts/alert";
import { Alert, Box, Close } from "theme-ui"

const AlertMsg = () => {
  const { isVisible, message, hideAlert, type } = useAlert();
  const backgroundColor = type === 'success' ? 'green' : 'red';

  useEffect(() => {
    if (isVisible && type === 'success') {
      const timer = setTimeout(() => {
        hideAlert();
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isVisible, type, hideAlert])
  return (
    <Box sx={{display: 'flex', justifyContent: 'center', alignItems:"center", mt: 2}}>
      <Alert variant={type} backgroundColor={backgroundColor} sx={{
        display: isVisible ? '' : 'none', 
        borderRadius: '5px',
        width: '90%',
        mt: 2,
        opacity: isVisible ? 1 : 0,
        transition: 'opacity 0.5s ease-out'
      }}>
        {message}
        <Close ml='auto' mr={-2} onClick={hideAlert}/>
      </Alert>
    </Box>
  )
}

export default AlertMsg;