import { Box, Flex } from "theme-ui";
import VotePill from "./votes-pill.component";
import CommentPill from "./comments-pill.component";


const InteractBar = ({post}) => {

  return (
    <Flex sx={{ pt: 3, alignItems: 'center'}}>
      <Box onClick={(e) => e.stopPropagation()} >
        <VotePill size={'1.5rem'} padding={'1'} post={post}/>
      </Box>
      <CommentPill commentCount={post.comment_count} size={'1.5rem'}/>
    </Flex>
  )
}

export default InteractBar;