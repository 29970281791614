import { useContext, useEffect, useState } from "react";
import { Box, Text } from "theme-ui";
import Comment from "./comment.component";
import { useParams } from "react-router-dom";
import { useAlert } from "../../../contexts/alert";
import { AuthContext } from "../../../contexts/auth";
import PropTypes from "prop-types";
import { useComments } from "../../../contexts/comment";

const CommentsList = ({post}) => {
  // const [allComments, setAllComments] = useState([]);
  // const {jwtToken} = useContext(AuthContext);
  const {comments, fetchComments, addReply} = useComments();
  const {showAlert} = useAlert();

  useEffect(() => {
    fetchComments();
  }, [post, fetchComments]);

  return (
    <Box py={2} ml={-4}>
      {comments.length === 0 ? (
        <Text ml={4} sx={{color: 'lightgray'}}>No comments yet. Be the first to comment!</Text>
      ) : (
        comments.map(comment => (
          <Comment key={comment.id} comment={comment} post={post} addReply={addReply}/>
        ))
      )}
    </Box>
  );
}

CommentsList.propTypes = {
  post: PropTypes.shape({
    sub_name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  temporaryComment: PropTypes.object,
};

export default CommentsList;