import { forwardRef } from "react";
import { Container, Label, Input, Box } from "theme-ui";

const FormInput = forwardRef((props, ref) => {
  return (
    <Container mb={3}>
      <Label htmlFor={props.name} className="form-label">
        {props.title}
      </Label>
      <Input 
        type={props.type}
        id={props.name}
        ref={ref}
        name={props.name}
        placeholder={props.placeholder}
        onChange={props.onChange}
        autoComplete={props.autoComplete}
        value={props.value}
        sx={{'::placeholder': {fontSize: '0.85rem'}}}
      />
      <Box className={props.errorDiv}>{props.errorMsg}</Box>
    </Container>
  )
})

export default FormInput;