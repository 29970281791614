import { useEffect } from "react";
// import { Quill } from "react-quill";
import 'quill/dist/quill.snow.css';

// import MarkdownShortcuts from 'quill-markdown-shortcuts';
import { Box } from "theme-ui";
import ReactQuill from "react-quill";
import Quill from "quill";
import CustomImageBlot from "./CustomImageBlot";


// Quill.register('modules/markdownShortcuts', MarkdownShortcuts);
console.log(Quill.imports);


const QuillEditorComponent = ({setContent, quillRef}) => {

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      if (editor) {
        editor.on('text-change', () => {
          console.log('Quill content:', editor.root.innerHTML);
          setContent(editor.root.innerHTML);
        });
      }
    }
  }, [setContent]);

  const modules = {
    toolbar: false,
  };

  return (
    <Box>
      <ReactQuill
        ref={quillRef}
        theme="snow"
        modules={modules}
        placeholder="Write your comment..."
      />
    </Box>
  );
}

export default QuillEditorComponent;