import type { Theme } from 'theme-ui';

export const theme: Theme = {
  fonts: {
    body: 'system-ui, sans-serif',
    heading: '"Avenir Next", sans-serif',
    monospace: 'Menlo, monospace',
  },
  config: {
    initialColorModeName: 'light',
  },
  colors: {
    text: '#000',
    background: '#fff',
    primary: '#33e',
    buttonBg: '#000', // Default button background color
    buttonText: '#fff', // Default button text color
    cardBg: 'white',
    modes: {
      dark: {
        text: '#fff',
        background: '#000',
        primary: '#0cf',
        buttonBg: 'white',
        buttonText: 'black', 
        cardBg: 'grey',
        border: '#444',
      },
    }
  },
  shadows: {
    light:  '0 2px 4px rgba(0, 0, 0, 0.1)',
    dark: '0 2px 4px rgba(255, 255, 255, 0.2)',
  },
  breakpoints: ['30em', '40em', '52em', '64em', '75em'],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  text: {
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.2em',
      
    },
    heading: {
      fontFamily: 'heading',
      fontWeight: 'bold',
      lineHeight: '1.25',
    },
  },
  buttons: {
    toggleDarkmode: {
      color: 'buttonText',
      bg: 'buttonBg',
      padding: '0.5em',
      border: 'none',
      '&:hover': {
        bg: 'darkred',
        color: 'white',
      },
    },
    link: {
      background: 'none',
      color: 'primary',
      padding: 0,
      border: 'none',
      cursor: 'pointer',
      textDecoration: 'none',
      '&:hover': {
        color: 'secondary',
      }
    }
  },
  cards: {
    primary: {
      padding: 2,
      borderRadius: 4,
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)',
    },
    compact: {
      padding: 1,
      borderRadius: 2,
      border: '1px solid',
      borderColor: 'muted',
    },
    toggleDarkmode: {
      bg: 'cardBg',
    }
  },
};