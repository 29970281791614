import { EmbedBlot } from 'parchment';
import DOMPurify from 'dompurify';

const ATTRIBUTES = ['alt', 'height', 'width', 'style'];

class CustomImageBlot extends EmbedBlot {
  static create(value) {
    const node = super.create(value);
    if (typeof value === 'object') {
      if (value.src) {
        node.setAttribute('src', this.sanitize(value.src));
      }
      // Apply styles if provided
      if (value.style) {
        node.setAttribute('style', value.style);
        console.log('Setting style:', value.style);
      }
    }
    return node;
  }

  static formats(domNode) {
    return ATTRIBUTES.reduce((formats, attribute) => {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }

  static match(url) {
    return /\.(jpe?g|gif|png)$/.test(url) || /^data:image\/.+;base64/.test(url);
  }

  static sanitize(url) {
    // Configure DOMPurify to allow specific protocols
    return DOMPurify.sanitize(url, {
      ALLOWED_PROTOCOLS: ['http', 'https', 'data']
    });
  }

  static value(domNode) {
    return {
      src: domNode.getAttribute('src'),
      style: domNode.getAttribute('style')
    };
  }

  format(name, value) {
    if (ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
        console.log(`Formatting ${name} with value:`, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

CustomImageBlot.blotName = 'image';
CustomImageBlot.tagName = 'IMG';

export default CustomImageBlot;
