import { formatDistanceToNow } from "date-fns";
import { Box, Link, Text } from "theme-ui";
import intlFormattedDate from "../utils/date/date.utils";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/auth";


const CommentHeaderInfo = React.memo(({comment, post}) => {
  const { username, created_at, id, post_id } = comment;
  const { sub_name } = post;
  const {authUser} = useContext(AuthContext);

  const [formattedDate, setFormattedDate] = useState("");
  const [formattedUsername, setFormattedUsername] = useState("");

  useEffect(() => {
    
    const createdAtDate = new Date().toISOString();

    if (isNaN(createdAtDate)) {
      setFormattedDate(`${formatDistanceToNow(createdAtDate)} ago`);
    } else {
      setFormattedDate(`${formatDistanceToNow(new Date())} ago`);
    }
    

    if (username) {
      setFormattedUsername(username);
    } else {
      setFormattedUsername(authUser);
    }
  }, [created_at, username, authUser]);

  console.log(formattedUsername);
  console.log("date:", formattedDate);
  
  
  // if (!formattedDate || !formattedUsername ) {
  //   return (
  //     <Box sx={{ mr:'auto', alignItems:'center'}}>
  //     <Link href={`/v1/user/${authUser}`} 
  //       sx={{
  //         fontWeight: 'bold',
  //         color: 'inherit', 
  //         textDecoration: 'none', 
  //         '&:hover': {
  //           color: 'primary'
  //       }}}
  //     >{authUser}</Link> • <Link 
      
  //       href={`/v1/posts/${comment.sub_name}/comments/${comment.post_id}/comment/${id}`}
  //       sx={{textDecoration: 'none', color:'inherit'}}
  //       title={`${intlFormattedDate(new Date().toISOString())}`}
  //     >
  //       <Text sx={{fontStyle:'oblique'}}>{`${formatDistanceToNow(new Date().toISOString())} ago`}</Text>
  //     </Link>
  //     </Box>
  //   )
  // }

  return (
    <Box sx={{ mr:'auto', alignItems:'center'}}>
      <Link href={`/v1/user/${comment.username}`} 
        sx={{
          fontWeight: 'bold',
          color: 'inherit', 
          textDecoration: 'none', 
          '&:hover': {
            color: 'primary'
        }}}
      >{comment.username}</Link> • <Link 
      
        href={`/v1/posts/${sub_name}/comments/${post_id}/comment/${id}`}
        sx={{textDecoration: 'none', color:'inherit'}}
        title={`${intlFormattedDate(created_at)}`}
      >
        <Text sx={{fontStyle:'oblique'}}>{`${formatDistanceToNow(new Date(created_at))} ago`}</Text>
      </Link>
      
    </Box>
  )
});

export default CommentHeaderInfo;