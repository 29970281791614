import { Box, Button, useColorMode } from "theme-ui";


const Modal = ({ isOpen, onClose, children }) => {
  const [colorMode] = useColorMode();
  if (!isOpen) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        bg: 'rgba(0,0,0,0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
        cursor: 'default',
        
      }}
    >
      <Box
        sx={{
          bg: 'background',
          borderRadius: '8px',
          width: ['90%', '70%', '50%'],
          maxWidth: '60%',
          boxShadow: colorMode === 'dark' ? '0 2px 10px rgba(255, 255, 255, 0.2)' : '0 2px 10px rgba(0, 0, 0, 0.2)',
          p: 4,
          position: 'relative',
        }}
      >
        <Button
          p={2}
          sx={{
            position: 'absolute',
            top: 2,
            right: 2,
            bg: 'transparent',
            border: 'none',
            cursor: 'pointer',
            fontSize: 4,
            color: 'text',
            '&:hover': {
              color: 'primary',
            },
          }}
          onClick={onClose}
        >
          &times;
        </Button>
        {children}
      </Box>
    </Box>
  )
}
export default Modal;