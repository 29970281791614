import { Routes, Route } from 'react-router-dom';

import Home from './routes/portfolio/home/home.component.jsx';

import About from './components/portfolio/About.jsx';
import Login from './components/portfolio/login/login.component.jsx';
import LinkupHome from './routes/linkup/home/home.component.jsx';
import LinkupLogin from './components/linkup/login/linkupLogin.component.jsx';
import LinkupPost from './routes/linkup/posts/post.component.jsx';

import ForgotPassword from './components/portfolio/login/forgot-password.component.jsx';
import LinkupForgotPassword from './components/linkup/login/linkup-forgot-password.component.jsx';
import ResetPassword from './components/portfolio/login/reset-password.component.jsx';
import LinkupResetPassword from './components/linkup/login/linkup-reset-password.component.jsx';
import DirectoryLayout from './routes/linkup/navigation/directory-layout.component.jsx';
import LeftNav from './routes/linkup/navigation/left-nav.component.jsx';
import Directory from './routes/portfolio/navigation/directory.component.jsx';
import SearchSubthreadsResults from './routes/linkup/posts/search-subthreads.component.jsx';
import RightContent from './routes/linkup/navigation/right-content.component.jsx';
import CreatePost from './routes/linkup/posts/CreatePost.jsx';
import SubthreadPosts from './routes/linkup/subthreads/list-subthread-posts.component.jsx';
import EditPost from './routes/linkup/posts/EditPost.jsx';
import LinkupAll from './routes/linkup/home/AllPage.jsx';
import { CommentProvider } from './contexts/comment.js';
import { PostProvider } from './contexts/post.js';
import { SubthreadProvider } from './contexts/subthread.js';
import ContactForm from './components/portfolio/forms/ContactForm.jsx';
import Modal from './components/modal/modal.jsx';
import Contact from './components/portfolio/Contact.jsx';
import Services from './components/portfolio/services.component.jsx';

const App = () => {  
  return ( 
    <>
    <Routes>
      <Route path='/' element={<Directory />}>
        <Route index element={<Home/>} />
        <Route path='about' element={<About/>} />
        <Route path='contact' element={<ContactForm />} />
        <Route path='services' element={<Services/>} />
        <Route path='login' element={<Login/>} />
        <Route path='forgot-password' element={<ForgotPassword/>} />
        <Route path='reset-password' element={<ResetPassword/>} />
      </Route>
      <Route path='/linkup' 
      element={
        <PostProvider>
          <DirectoryLayout 
            leftContent={<LeftNav />} 
            rightContent={<RightContent />} 
          />
        </PostProvider>
      }>
        <Route index element={<LinkupHome/>} />
        <Route path='explore' element />
        <Route path='all' element={<LinkupAll/>} />
        <Route path='login' element={<LinkupLogin/>} />
        <Route path='forgot-password' element={<LinkupForgotPassword/>} />
        <Route path='p/:subname/comment/:pid/:slug' 
          element={
            <CommentProvider>
              <LinkupPost/>
            </CommentProvider>
          } 
        />
        <Route path='p/:subname/comment/:pid/:slug/edit' element={<EditPost/>} />
        <Route path='p/:subname/submit' element={<CreatePost/>}/>
        <Route path='submit' element={<CreatePost/>}/>
        <Route path='t/:subname' element={
        <SubthreadProvider>
          <SubthreadPosts/>
        </SubthreadProvider>
        
          } 
        />
        <Route path='reset-password' element={<LinkupResetPassword/>} />
        <Route path='search-subthread' element={<SearchSubthreadsResults/>} />
      </Route>
    </Routes>
    
    </>
  );
};

export default App;
