import { Avatar, Box, Link } from "theme-ui";


const SubthreadAvatar = ({post}) => {
  
  return (
    <Box>
      <Link
        href={`/linkup/t/${post.subthread_id}`}
        sx={{
          fontWeight: 'bold',
          color: 'inherit', 
          textDecoration: 'none', 
          '&:hover': {
            color: 'primary'
        }}}
      >
        <Avatar 
          src={post.avatar_url ? post.avatar_url : `https://robohash.org/${post.sub_name}/set=set3`}
          sx={{
            backgroundColor: 'white', 
            width: 42, 
            height: 42,
            borderRadius: 99999,
            border: '1px solid lightgrey',
          }} 
        />
      </Link>
    </Box>
  )
}

export default SubthreadAvatar;