import React, { useEffect, useState } from 'react';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import { loadFull } from 'tsparticles';
import { Box } from 'theme-ui';

const ParticlesBackground = () => {

  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadFull(engine);

    }).then(() => {
      setInit(true);
    });
  }, []);


  const particlesLoaded = (container) => {
    console.log(container);
  };


  if (init) {

    return (
      <Particles
          id="tsparticles"
          // init={particlesInit}
          loaded={particlesLoaded}
          options={{
            style: {
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 0,
            },
            background: {
              color: {
                value: 'transparent',
              },
            },
            fpsLimit: 60,
            interactivity: {
              events: {
                onHover: {
                  enable: true,
                  mode: 'repulse',
                },
                resize: true,
              },
              modes: {
                repulse: {
                  distance: 100,
                  duration: 0.4,
                },
              },
            },
            particles: {
              color: {
                value: ['#ff0000', '#ff5f00', '#ffb700'],
              },
              links: {
                color: ['#ff0000', '#ff5f00', '#ffb700'],
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1,
              },
              collisions: {
                enable: false,
              },
              move: {
                direction: 'none',
                enable: true,
                outModes: {
                  default: 'bounce',
                },
                random: true,
                speed: 1,
                straight: false,
              },
              number: {
                density: {
                  enable: false,
                  area: 800,
                },
                value: 80,
              },
              opacity: {
                value: {min: 0.5, max: 0.7},
              },
              shape: {
                type: 'triangle',
              },
              size: {
                value: { min: 1, max: 5 },
              },
              glow: {
                enable: true,
                color: ['#ff0000', '#ff5f00', '#ffb700'],
                blur: 5,
                opacity: 0.5,
              },
            },
            detectRetina: true,
          }}
          
        />
    );
  }
  return <></>;
};

export default ParticlesBackground;
