import { Box, Card, Text, Button } from "theme-ui";

const ServiceCard = ({ service }) => (
  <Card sx={{
    variant: 'cards.toggleDarkmode',
    boxShadow: '0 2px 4px',
    borderRadius: 4,
  }}>
    <Box
      sx={{
        position: 'relative',
        borderRadius: 'inherit',
        overflow: 'hidden',
        width: '100%',
        height: '200px',
        backgroundImage: `url(${service.image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: 3,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        <Text sx={{ fontSize: [2, 3, 3] }}>{service.title}</Text>
        
      </Box>
    </Box>
    
    <Box sx={{ py: 3, px: 2, fontFamily: 'Roboto', fontSize: '18px' }}>
      <Text>{service.details}</Text>
    </Box>
    
  </Card>
);

export default ServiceCard;