import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../contexts/auth";
import { useAlert } from "../../../contexts/alert";
import LinkupPost from "./post.component";
import Modal from "../../../components/modal/modal";
import FormInput from "../../../components/form/form-input";
import { Box, Button, Flex, Grid, Heading, Label, Text } from "theme-ui";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import QuillPostEditor from "./QuillPostEditor";
import Subthreads from "../subthreads/list-subthreads.component";

const CreatePost = () => {
  const navigate = useNavigate();
  const {jwtToken} = useContext(AuthContext);
  const {showAlert} = useAlert();
  const [post, setPost] = useState();
  const {subname} = useParams();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    const savedDraft = JSON.parse(localStorage.getItem("draftPost"));
    if (savedDraft) {
      setTitle(savedDraft.title);
      setContent(savedDraft.content);
    }
  }, []);
  
  const handleSave = () => {
    const draftPost = {
      title: title,
      content: content,
    };
    localStorage.setItem("draftPost", JSON.stringify(draftPost));
    showAlert("Draft saved successfully!", "success");
  }


  const handleCreatePost = () => {

    const payload = {
      title: title,
      content: content,
    }
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwtToken}`
      },
      body: JSON.stringify(payload),
    }

    console.log(requestOptions);
  
    fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/posts/${subname}/submit`, requestOptions)
    .then((res) => {
      console.log(res);
      return res.json();
    })
    .then((data) => {
      console.log(data.post);
      if (data.post) {
        localStorage.removeItem("draftPost");
        setPost(data.post);
        navigate(`/linkup/${data.post.url}`);
      } else {
        showAlert(data.error.message, 'alert');
      }
      })
      .catch(error => {
        showAlert(error.message, 'alert'); // Ensure error.message is a string
      });

  }
  

  const titleLimitColor = title.length > 250 ? 'red' : 'gray'

  return (
    <>
      <Heading as={"h2"} mb={3}>
        Create Post
      </Heading>

      <Flex sx={{alignCenter: 'center', flexDirection: 'column'}}>
        <Subthreads subname={subname}/>
        <FormInput 
          title="Title"
          type="title"
          name="title"
          autoComplete="title-new"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          sx={{width: '100%'}}
        />
        {title.length < 1 && <Text sx={{mt: -3, fontSize: '0.75rem', color: titleLimitColor}}>Title must not be blank.</Text>}
        <Text 
          sx={{ 
            mt: -3,
            ml: 'auto',
            fontSize: '0.75rem', 
            color: titleLimitColor, 
            }}
          >{title.length}/250
        </Text>
        <Box py={2}>
          <Label mb={-2}>
            Content
          </Label>
          <QuillPostEditor setContent={setContent} content={content} />
        </Box>
        <Grid>
          <Box >
            <Flex sx={{ justifyContent: 'space-between', gap: 2 }}>
              <Button ml={'auto'} onClick={handleSave}
                sx={{backgroundColor: 'lightgrey', color: 'black', cursor: 'pointer'}}
              >Save</Button>
              <Button variant="primary" sx={{cursor:'pointer'}} onClick={handleCreatePost}>Submit</Button>
            </Flex>
          </Box>
        </Grid>
      </Flex>
    </>
  )
}

export default CreatePost;