import { createContext, useContext, useState } from "react";


const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const [alertState, setAlertState] = useState({
    isVisible: false,
    message: '',
    type: 'alert',
  })
  

  const showAlert = (message, type='alert') => {
    setAlertState({ isVisible:true, message, type})
  };

  const hideAlert = () => {
    setAlertState({...alertState, isVisible: false});
  };

  const value = {
    ...alertState,
    showAlert,
    hideAlert
  }
  return (
    <AlertContext.Provider value={value}>
      {children}
    </AlertContext.Provider>
  )
}

export const useAlert = () => useContext(AlertContext);