
const intlFormattedDate = (date) => {
  const newDate = new Date(date);
  if (isNaN(newDate.getTime())) {
    return "Invalid Date"; // or return a fallback message like "N/A"
  }
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short'
  };
  return new Intl.DateTimeFormat('en-US', options).format(newDate);
}

export default intlFormattedDate;