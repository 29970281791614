import React from 'react';
import { Box, Flex } from 'theme-ui';
import { Outlet, useLocation } from 'react-router-dom';
import LinkupNav from './navigation.component';
import LinkupSubthreadHeader from './LinkupSubthreadHeader';
import { SubthreadProvider } from '../../../contexts/subthread';


const DirectoryLayout = ({ leftContent, rightContent, children }) => {
  const location = useLocation();

  const isSubthreadRoute = /^\/linkup\/t\/[^/]+$/.test(location.pathname);

  return (
    <>
      <LinkupNav/>
      <Flex sx={{minHeight: '100vh', flexDirection: 'column'}}>
        <Flex sx={{ width: '100%', flexDirection: 'row', flexGrow: 1}}>
          <Box sx={{
            display: ['none', 'none', 'none', 'block'],
            width: ['0%', '0%', '0%', '15%'], 
            borderRight: '1px solid #d3d3d3',
            p:3, 
            bg: 'muted',
            }}
          >
            {leftContent}
          </Box>
          <Box sx={{
            width: ['100%', '100%', '100%', '85%'],
            flex: '1 1 auto', // Takes the remaining space
            display: 'flex',
            flexDirection: 'column',
            
          }}>
              <Box sx={{
                width: '100%',
                // bg: 'lightgray', // Adjust the background color or other styles as needed
                mb: 3,
                
              }}>
                {isSubthreadRoute && <SubthreadProvider><LinkupSubthreadHeader/></SubthreadProvider>}
                {children}
              </Box>
              
                <Flex sx={{flexGrow: 1, flexDirection: 'row', width: '100%'}}>
                  <Box sx={{
                    width: ['100%', '100%', '100%', '70%'], 

                    p: 3,
                    }}>
                    <Outlet />
                  </Box>

                  <Box as="aside" 
                    sx={{
                      display: ['none', 'none', 'none', 'block'],
                      width: ['0%', '0%', '0%', '30%'], 
                      
                      bg: 'muted',
                      px: 3,
                  }}>
                    {rightContent}
                  </Box>
                </Flex>
              </Box>
        </Flex>

      </Flex>
    </>
  )
}

export default DirectoryLayout;