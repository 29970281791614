import { Container } from "theme-ui";
import Posts from "../posts/list-posts.component";


const LinkupAll = () => {

  return (
    <>
      <Container>
      <div className="linkup-content">
        <div className="post-container">
          <Posts/>
        </div>
        <div className="comment-container">

        </div>
      </div>
      </Container>
    </>
  )
}

export default LinkupAll;