import { Container } from "theme-ui"
import SubscribedPosts from "../posts/SubscribedPosts"



const LinkupHome = () => {


  return (
    <>
      <Container  >
      <div className="linkup-content">
        <div className="post-container">
          <SubscribedPosts/>
        </div>
        <div className="comment-container">

        </div>
      </div>
      </Container>
    </>
  )
}

export default LinkupHome;