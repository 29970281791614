// src/quillSetup.js
import Quill from 'quill';
import CustomImageBlot from './components/linkup/editor/CustomImageBlot';


// Register the custom blot
// Quill.register({'formats/customImage': CustomImageBlot}, true);
Quill.register('formats/image', CustomImageBlot);

// console.log('CustomImageBlot registered:', Quill.imports['formats/image']);
