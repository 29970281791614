import React from 'react';
import { Box } from 'theme-ui';
import { FaTable, FaBold } from 'react-icons/fa'; 

const ToolbarButton = ({ icon: Icon, onClick }) => (
  <Box
    sx={{
      cursor: 'pointer',
      padding: 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
    onClick={onClick}
  >
    <Icon size={20} />
  </Box>
);

export default ToolbarButton;
