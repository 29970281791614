import { Avatar, Box, Button, Card, Flex, Grid, Heading, Image, Label, Paragraph, Progress, Text } from "theme-ui";
import FormInput from "../../form/form-input";
import { useContext, useEffect, useState } from "react";
import FormTextarea from "../../form/form-textarea";
import { AuthContext } from "../../../contexts/auth";
import formatErrors from "../../utils/auth/auth.utils";
import { useAlert } from "../../../contexts/alert";
import { useNavigate } from "react-router-dom";



const ContactForm = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [inquiry, setInquiry] = useState("");
  const navigate = useNavigate();
  
  const { showAlert, hideAlert } = useAlert();

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^(?:\+1[-.\s]?)?(?:(\(\d{3}\))|(\d{3}))[-.\s]?(\d{3})[-.\s]?(\d{4})$/;
  

  const handleSubmit = (e) => {
    e.preventDefault();
    

    if (name.length < 1 || email.length < 1 || inquiry.length < 1 || !emailRegex.test(email) || !phoneRegex.test(phone) || name.length > 100 || email.length > 254 || inquiry.length > 1000) {

      return;
    }



    let payload = {
      name: name,
      email: email,
      phone: phone,
      company: company, 
      inquiry: inquiry,
    }

    const requestOptions = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    }

    fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/contact-me`, requestOptions)
    .then((resp) => {
      return resp.json();
    })
    .then((data) => {
      if (data.error) {
          console.error('Unknown error occurred');
          showAlert(data.Error, 'alert')
        } else {
          setName('');
          setEmail('');
          setPhone('');
          setCompany('');
          setInquiry('');

          showAlert(data.message, 'success');
          navigate('/');
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    })
  }
  const nameLimitColor = name.length > 100 ? 'red' : 'gray'
  const emailLimitColor = email.length > 254 ? 'red' : 'gray'
  const phoneLimitColor = !phoneRegex.test(phone) ? 'red' : 'gray'
  const inquiryLimitColor = inquiry.length > 1000 ? 'red' : 'gray'
  
  return (
    <>
      <Flex pt={4} sx={{ justifyContent: 'center', alignItems: 'center'}}>
        <Box sx={{ width: ['90%', '80%', '80%', '50%'], mx: 'auto', px: 4 }}>
          <Heading as={'h2'}>Feel free to reach out!</Heading>
          <Paragraph pt={2} sx={{fontSize:'0.9rem'}}>Please submit your inquiry here.</Paragraph>
          
          <Box as="form" mt={3} onSubmit={handleSubmit} >
            <Grid gap={4} columns={[1]} sx={{alignItems:'stretch'}}>
              <Box>
                <Flex
                  sx={{alignContent: 'center', flexDirection: 'column'}}
                >
                  
                  <FormInput 
                    title="Name"
                    type="name"
                    name="name"
                    autoComplete="name-new"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    sx={{width:'100%'}}
                  />
                  {name.length < 1 && <Text sx={{mt: -3, fontSize: '0.75rem', color: "gray"}}>Name must not be blank.</Text>}
                  {name.length > 100 && <Text sx={{mt: -3, fontSize: '0.75rem', color: nameLimitColor}}>Limit of 100 characters.</Text>}
                  <Text 
                    sx={{ 
                      mt: -3,
                      ml: 'auto',
                      fontSize: '0.75rem', 
                      color: nameLimitColor, 
                      }}
                  >{name.length}/100
                  </Text>
                </Flex>
                <Flex
                  sx={{alignContent: 'center', flexDirection: 'column'}}
                >
                  <FormInput 
                    title="Email"
                    type="email"
                    name="email"
                    autoComplete="email-new"
                    placeholder="e.g., example@email.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{width:'100%'}}
                  />
                  {email.length < 1 && <Text sx={{mt: -3, fontSize: '0.75rem', color: "gray"}}>Email must not be blank.</Text>}
                  {email.length > 0 && !emailRegex.test(email) && <Text sx={{mt: -3, fontSize: '0.75rem', color: "red"}}>Must enter a valid email address.</Text>}
                  <Text 
                    sx={{ 
                      mt: -3,
                      ml: 'auto',
                      fontSize: '0.75rem', 
                      color: emailLimitColor, 
                      }}
                  >{email.length}/254
                  </Text>
                </Flex>
                <Flex
                  sx={{alignContent: 'center', flexDirection: 'column'}}
                >
                  <FormInput 
                    title="Phone"
                    type="phone"
                    name="phone"
                    autoComplete="phone-new"
                    placeholder="e.g., 123-456-7890, (123) 456-7890, 1234567890"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    sx={{width:'100%'}}
                    
                  />
                  {phone.length < 1 && <Text sx={{mt: -3, fontSize: '0.75rem', color: "gray"}}>Phone number must not be blank.</Text>}
                  {phone.length > 0 && !phoneRegex.test(phone) && <Text sx={{mt: -3, fontSize: '0.75rem', color: phoneLimitColor}}>Phone number must be a US number.</Text>}
                  
                </Flex>
                <Flex
                  sx={{alignContent: 'center', flexDirection: 'column'}}
                >
                  <FormInput 
                    title="Company"
                    type="company"
                    name="company"
                    autoComplete="company-new"
                    placeholder="Company Name (optional)"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    sx={{width:'100%'}}
                    
                  />
                  
                </Flex>
                <Flex
                  sx={{alignContent: 'center', flexDirection: 'column'}}
                >

                <FormTextarea 
                  title="Inquiry"
                  type="inquiry"
                  name="inquiry"
                  autoComplete="inqury-new"
                  value={inquiry}
                  onChange={(e) => setInquiry(e.target.value)}
                  sx={{resize:'none'}}
                />
                  {inquiry.length < 1 && <Text sx={{mt: -3, fontSize: '0.75rem', color: inquiryLimitColor}}>Inquiry must not be blank.</Text>}
                <Text
                  sx={{ 
                    ml: 'auto',
                    fontSize: '0.75rem', 
                    color: inquiryLimitColor, 
                    mt: -3,
                    }}
                >{inquiry.length}/1000</Text>
                </Flex>
                <Box sx={{pt:'3'}}>
                  <Flex sx={{alignItems: 'center', justifyContent:'space-between'}}>
                    <Box sx={{width: '50%'}}>
                      <Progress max={2} value={1} />
                    </Box>
          
                      <Button
                        type="button"
                        onClick={() => navigate(-1)}
                        sx={{
                          ml:'auto', 
                          mr:'2', 
                          backgroundColor:'lightgray', 
                          color:'black',
                          cursor:'pointer'
                        }}
                      >Cancel</Button>
                      <Button
                        sx={{cursor:'pointer'}}
                        type="submit"
                      >Submit</Button>
                  
                  </Flex>
                </Box>
              </Box>       
            </Grid>
          </Box>
        </Box>
      </Flex>
    </>
  )
}

export default ContactForm;