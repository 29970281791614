import { useState } from "react"
import { Box, Flex, Heading, Text } from "theme-ui";
import SearchSubthreadBar from "../../../components/search/search-thread-bar";


const SearchSubthreadsResults = ({setSubthreadName, setIsSearching}) => {
  const [searchResults, setSearchResults] = useState([]);

  const handleSearchResults = (results) => {
    setSearchResults(results);
  };



  return (
    <Box sx={{p:3}}>
      <SearchSubthreadBar onSearchResults={handleSearchResults} setSubthreadName={setSubthreadName} setIsSearching={setIsSearching}/>
      <Flex sx={{
        flexDirection: 'column',
        mt: 3,
      }} >
        {searchResults.length > 0 ? (
          searchResults.map((subthread) => (
            <Box 
              key={subthread.id} 
              sx={{ 
                mb: 2,
                p: 2,
                border: '1px solid #ddd',
                borderRadius: '4px',
              }}>
                <Text sx={{fontSize: 2}}>
                  {subthread.name}
                </Text>

            </Box>
          )))
          : (
          <Text>No results found</Text>
        )}
      </Flex>
    </Box>

  )
}

export default SearchSubthreadsResults;