import { forwardRef } from "react";
import { Container, Label, Box, Textarea } from "theme-ui";

const FormTextarea = forwardRef((props, ref) => {
  return (
    <Container mb={3}>
      <Label htmlFor={props.name} className="form-label">
        {props.title}
      </Label>
      <Textarea 
        rows={8}
        type={props.type}
        id={props.name}
        ref={ref}
        name={props.name}
        placeholder={props.placeholder}
        onChange={props.onChange}
        autoComplete={props.autoComplete}
        value={props.value}
        sx={{resize:'none'}}
      />
      <Box className={props.errorDiv}>{props.errorMsg}</Box>
    </Container>
  )
})

export default FormTextarea;