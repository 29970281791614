import { formatDistanceToNow } from "date-fns";
import { Box, Link, Text } from "theme-ui";
import intlFormattedDate from "../utils/date/date.utils";


const HeaderInfoWithUsername = ({post}) => {

  return (
    <Box sx={{fontSize: '14px', pt: '1'}}>
        <Link href={`/linkup/t/${post.subthread_id}`} 
          sx={{
            fontWeight: 'bold',
            color: 'inherit', 
            textDecoration: 'none', 
            '&:hover': {
              color: 'primary'
          }}}
        >t/{post.subthread_id}</Link> • <Text 
          title={`${intlFormattedDate(post.created_at)}`}
          sx={{fontStyle:'oblique'}}>{`${formatDistanceToNow(new Date(post.created_at))} ago`}
        </Text>
        <Box>
          <Link href={`/v1/user/${post.username}`} 
            sx={{
              color: 'inherit', 
              textDecoration: 'none', 
              '&:hover': {
                color: 'primary'
              }}}
          >{post.username}</Link>
        </Box>
      </Box>
  )
}

export default HeaderInfoWithUsername;