import { createContext, useContext, useEffect, useState } from "react";

const RecentPostsContext = createContext();

export const RecentPostsProvider = ({children}) => {
  const [recentPosts, setRecentPosts] = useState([]);

  useEffect(() => {
    try {
      const storedPosts = JSON.parse(localStorage.getItem('recentPosts')) || [];
      console.log('Loaded recent posts from localStorage:', storedPosts); // Add logging
      setRecentPosts(storedPosts); // Set to state
    } catch (error) {
      console.error('Failed to load recent posts:', error);
    }
  }, []);

  const updateRecentPosts = (posts) => {
    setRecentPosts(posts);
    localStorage.setItem('recentPosts', JSON.stringify(posts));
    console.log('Updated recent posts:', posts); // Add logging
  }

  return (
    <RecentPostsContext.Provider value={{recentPosts, updateRecentPosts}}>
      {children}
    </RecentPostsContext.Provider>
  )
}

export const useRecentPosts = () => useContext(RecentPostsContext);