

const formatErrors = (errors) => {
  let formattedError = "";
  for (const key in errors) {
    if (errors.hasOwnProperty(key)) {
      formattedError += `${key}: ${errors[key]}\n`;
    } else {
      formattedError += errors.message;
    }
  }
  return formattedError.trim();
}

export default formatErrors;