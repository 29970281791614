import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Container, Flex, Heading, Paragraph } from "theme-ui";
import Dropdown from "../../../components/linkup/dropdown/dropdown.component";
import BackButton from "../../../components/linkup/button/back-button";
import HeaderTag from "../../../components/headers/header-tag.component";
import SubthreadAvatar from "../../../components/headers/subthread-avatar";
import HeaderInfoWithUsername from "../../../components/headers/header-info-username.component";
import InteractBar from "../../../components/linkup/pills/interact.component";
import { AuthContext } from "../../../contexts/auth";
import CommentsList from "../../../components/linkup/comments/comments-list.component";
import AddComment from "../../../components/linkup/button/add-comment-button";
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { useRecentPosts } from "../../../contexts/recentPosts";
import { useComments } from "../../../contexts/comment";

const LinkupPost = () => {
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);

  const {jwtToken} = useContext(AuthContext);
  const {recentPosts, updateRecentPosts} = useRecentPosts();
  const {comments, addComment, fetchComments} = useComments();
  const {subname, pid, slug} = useParams();
  
  useEffect(() => {
    console.log(subname, pid);
    const fetchPost = async () => {
      
      try {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        // headers.append("Authorization", `Bearer ${jwtToken}`);
        
        const requestOptions = {
          method: "GET",
          headers: headers
        }
        const response = await fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/posts/${subname}/${pid}/${slug}`, requestOptions);
        const data = await response.json();
        setPost(data.post);

        
      } catch (err) {
        setError(err.message);
      }
    };
    fetchPost();

  },[subname, pid, slug]);

  useEffect(() => {
    if (post && jwtToken) {
      console.log(post);
      fetchComments();
    }
  }, [post, jwtToken, fetchComments]);

  useEffect(() => {
    if (post) {
      handlePostClick(post);
    }
  }, [post]);

  const handlePostClick = (post) => {
    let updatedPosts = [post, ...recentPosts.filter(p => p.id !== post.id)];

    if (updatedPosts.length > 10) {
      updatedPosts = updatedPosts.slice(0, 10);
    }

    updateRecentPosts(updatedPosts);
    localStorage.setItem('recentPosts', JSON.stringify(updatedPosts));
  };

  const handleNewComment = (newComment) => {
    addComment(newComment);
  }
  
  if (error) {
    return <div>Error: {error}</div>
  }
  
  if (!post) {
    return <div>No post available</div>;
  }
  
  
  return (
    <>
      <Container px={[4]} >
        <Flex sx={{ justifyContent: 'space-between', alignItems: 'center', py: 3 }}>
          <BackButton />
          <HeaderTag avatar={<SubthreadAvatar post={post} />} headerInfo={<HeaderInfoWithUsername post={post} />} sx={{mr: 'auto'}}/>
          <Dropdown username={post.username} url={post.url} />
        </Flex>
          <Box >
            <Heading as="h3" sx={{ mb: 2}}>
              {post.title}
            </Heading>
            <Paragraph sx={{ fontSize: '0.9rem'}}>
            <ReactMarkdown
              className={"markdown-content"}
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
            >
            {post.content}
            </ReactMarkdown>
            </Paragraph>
            <InteractBar post={post} />
          </Box>
          <Box py={3}>
            <AddComment post={post} setIsExpanded={() => {}} onNewComment={handleNewComment}/>
          </Box>
          <CommentsList post={post} />
      </Container>
    </>
  )
}

export default LinkupPost;