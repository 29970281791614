import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Button, Link } from "theme-ui";


const BackButton = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Link onClick={handleBack} 
    sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      color: 'inherit', 
      textDecoration: 'none',
      cursor: 'pointer',
    }}>
      <FaArrowLeft style={{ marginRight: '8px' }} />
    </Link>
  )
}

export default BackButton;