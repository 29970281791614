const CACHE_EXPIRATION_TIME = 1000 * 60 * 5; // 5 minutes

const cache = {};

export function getFromCache(key) {
  const cachedItem = cache[key];
  if (cachedItem && Date.now() - cachedItem.timestamp < CACHE_EXPIRATION_TIME) {
    return cachedItem.value;
  }
  return null;
}

export function saveToCache(key, value) {
  cache[key] = {
    value,
    timestamp: Date.now()
  };
}
