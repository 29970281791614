import { useNavigate } from "react-router-dom"
import { Card, Flex, Heading, Link, Text, Box, useColorMode } from "theme-ui";
import Dropdown from "../dropdown/dropdown.component";
import SubthreadLink from "../../headers/subthread-link";
import InteractBar from "../pills/interact.component";
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";


const ListSubthreadPost = ({post}) => {
  const [colorMode] = useColorMode();
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/linkup/p/${post.subthread_id}/comment/${post.id}/${post.slug}`);
  };

  return (
    <Card sx={{ 
        p: 3, 
        cursor: "pointer",
        transition: "background-color 0.3s ease",
        backgroundColor: colorMode === 'dark' ? 'background' : 'white',
        '&:hover': {
          backgroundColor: colorMode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : '#f0f0f0',
        },
      }} onClick={handleCardClick}>
      <Flex sx={{ alignItems: 'center' }}>
        <SubthreadLink post={post} sx={{mr: 'auto'}}/>
        <Box onClick={(e) => e.stopPropagation()} >
          <Dropdown post={post}/>

        </Box>
      </Flex>
      <Heading as="h2" sx={{ fontSize: 2, mb: 2}}>
        {post.title}
      </Heading>
      <Text sx={{ fontSize: '0.9rem'}}>
        <ReactMarkdown
          className={"markdown-content"}
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
        >
          {post.content}
        </ReactMarkdown>
      </Text>
      <InteractBar comments={post.comment_count} post={post}/>
    </Card>
  )
}

export default ListSubthreadPost;