import { useEffect, useRef, useState } from "react";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { Box, Link } from "theme-ui";


const CollapseButton = ({ isCollapsed, toggleCollapse }) => {
  
  return (
    <Link 
        className="comment-icon"
        pt={1}
        pr={2}
        onClick={toggleCollapse} 
        style={{
          textDecoration: 'none',
          color: 'inherit',
        }}
      >
        <Box paddingY={'0.29rem'}>  
          {isCollapsed ? <FaPlusCircle /> : <FaMinusCircle />}
        </Box>
      </Link>
    
  )
}

export default CollapseButton;