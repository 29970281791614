import { Grid } from "theme-ui";


const HeaderTag = ({avatar, headerInfo}) => {

  return (
    <Grid columns={['1fr 5fr']} sx={{mr: 'auto'}}>
      {avatar}
      {headerInfo}
    </Grid>
  )
}

export default HeaderTag;