import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/auth";
import { useParams } from "react-router-dom";
import { Grid } from "theme-ui";
import ListPost from "../../../components/linkup/card/list-post";
import ListSubthreadPost from "../../../components/linkup/card/ListSubthreadPosts";


const SubthreadPosts = () => {
  const {jwtToken} = useContext(AuthContext);
  const {subname} = useParams();
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    if (!jwtToken) {
      return;
    }
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwtToken}`
      }
    }

    fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/posts/${subname}`, requestOptions)
    .then((resp) => {
      return resp.json();
    })
    .then((data) => {
      console.log('subthread post data:', data);
      setPosts(data.posts);
    })
    .catch(error => {
      console.errors('Error fetching posts:', error);
    })
  }, [jwtToken, subname])

  return (
    <>
      <Grid gap={1} columns={[1,1,1]}> 
        {posts.map((post) => (
          <ListSubthreadPost key={post.id} post={post}/>
        ))}
      </Grid>
    </>
  )
}

export default SubthreadPosts;