import { useContext, useEffect, useState } from "react"
import { BsArrowDownSquare, BsArrowDownSquareFill, BsArrowUpSquare, BsArrowUpSquareFill } from "react-icons/bs";
import { FaArrowAltCircleDown, FaArrowAltCircleUp, FaArrowCircleUp, FaArrowDown, FaArrowUp, FaLongArrowAltUp } from "react-icons/fa";
import { Box, useThemeUI } from "theme-ui";
import { AuthContext } from "../../../contexts/auth";
import { useVotes } from "../../../contexts/vote";


const VotePill = ({size, padding, post}) => {
  const {votes, updateVote} = useVotes();
  const [vote, setVote] = useState(votes[post.id] || null);
  const [totalVotes, setTotalVotes] = useState(post.votes);
  const {jwtToken} = useContext(AuthContext);
  const {theme} = useThemeUI();
  const isDarkMode = theme.colors.modes && theme.colors.modes.dark;
  

  const fetchPostData = () => {
    fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/posts/${post.sub_name}/${post.id}`, {
      method: 'GET',
      headers: {
        "Authorization": `Bearer ${jwtToken}`
      }
    })
    .then(response => response.json())
    .then(data => {
      setTotalVotes(data.post.votes);
      
    })
    .catch(error => console.error("Error:", error));
  };

  const handleVote = (newVote) => {
    const voteType = vote === newVote ? 0 : newVote;
    const payload = {
      vote_type: voteType,
    }
    const headers = new Headers();
    headers.append("Content-Type", "application/json")
    headers.append("Authorization", `Bearer ${jwtToken}`)
    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    } 
    fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/posts/${post.id}/vote`, requestOptions)
    .then((resp) => {
      return resp.json();
    })
    .then(() => {
      fetchPostData();
    })
    
    .catch(error => {
      console.error(error);
    })
  }


  return (
    <Box
      mr={2}
      sx={{
        backgroundColor: totalVotes ? (isDarkMode ? 'white' : 'primary') : '#f0f0f0',
        color: isDarkMode ? 'black': 'white',
        p: `${padding}`,
        borderRadius: '18px',
        display:'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'default',
      }}    
      >
      <Box 
        sx={{ 
          color: 'black',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '2rem',
          height: '1.5rem',
          '&:hover':{
            backgroundColor: isDarkMode ? '#333' : 'white',
            color: 'primary',
            cursor: 'pointer',
            borderRadius: '50%',
          },

        }}
        onClick={() => handleVote(1)}
      >
        {
        totalVotes === 1 ? 
        <FaArrowUp
          size={size}
          style={{ color: isDarkMode ? 'black' : 'white' }}
        />
        :
        <FaArrowAltCircleUp 
          size={size}
          style={{ color: totalVotes === -1 ? (isDarkMode ? 'black' : 'white') : 'inherit' }}
        /> 

        }
      </Box>
      <Box 
        px={1} 
        sx={{
          fontSize: '12px', 
          fontWeight: 'bold',
          color: totalVotes === 1 || totalVotes === -1 ? (isDarkMode ? 'black' : 'white') : 'black',
        }}>
          {totalVotes}
      </Box>
      <Box
        sx={{ 
          color: 'black',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '2rem',
          height: '1.5rem',
          '&:hover':{
            backgroundColor: '#f0f0f0',
            color: 'primary',
            cursor: 'pointer',
            borderRadius: '50%',
          },
          // borderRadius: '20%',
          // padding: '2px',
        }}
        onClick={()=> handleVote(-1)}
      >
        {
        totalVotes === -1 ? 
        <FaArrowDown
          size={size}
          style={{ color:  isDarkMode ? 'black' : 'white' }}
        />
        :
        <FaArrowAltCircleDown 
          size={size}
          style={{ color: totalVotes === 1 ? (isDarkMode ? 'black' : 'white') : 'inherit' }}
        /> 

        }
      </Box>
    </Box>   
  );
};

export default VotePill;