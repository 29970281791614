import { Avatar, Box, Button, Flex, Heading, Image, Text, useColorMode } from "theme-ui";
import { usePost } from "../../../contexts/post";
import { useSubthread } from "../../../contexts/subthread";
import { useContext } from "react";
import { AuthContext } from "../../../contexts/auth";


const LinkupSubthreadHeader = () => {
  const {subthread} = useSubthread();
  const [colorMode] = useColorMode();
  const {userRole} = useContext(AuthContext);
  console.log("subthread:", subthread);
  if (!subthread) {
    return; // Handle loading state if the post is not yet fetched
  }
  return (
    <>
    <Flex sx={{flexGrow: 1, flexDirection: 'row', width: '100%'}}>
      {subthread.header_image_url ? (
        <Image src={subthread.header_image_url} 
          sx={{
            borderRadius:'5px',
            mx: '3',
            my: '2',
            width: '100%',
            height: '8em',
            objectFit: 'cover',
  
          }}
        />
        ) : (
        <Box sx={{
          mx:'3', 
          my: '2', 
          border: '4em solid gray', 
          borderRadius: '5px', 
          width: '100%', 
          height: '100%', 
          backgroundColor: 'gray'
        }}/>
        )}
      </Flex>
    <Flex>
      <Avatar src={subthread.avatar_url ? subthread.avatar_url : `https://robohash.org/${subthread.id}/set=set3`} 
        sx={{
          mx: '3',
          mt: '-5',
          ml: '4',
          border: `0.4em solid ${colorMode === 'dark' ? 'black' : 'white'}`,
          backgroundColor: 'white', 
          width: 100, 
          height: 100,
          borderRadius: 99999,
          
        }}
      />
      <Heading as="h1">t/{subthread.id}</Heading>
      {(userRole === 1 || userRole === 2) && (
        <Button 
          sx={{
            ml:'auto', 
            mr: '4',
            cursor:'pointer', 
            borderRadius: '20px',
            fontSize: '0.9rem',
            color: 'black',
            border: '1px solid black',
            background: 'white',
            fontWeight: 'bold',
          }}
        >Edit</Button>
      )}
    </Flex>
    </>
  )
}

export default LinkupSubthreadHeader;